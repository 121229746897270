import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import PoECardsPoECards from 'components/Products/Power_over_Ethernet/PoECardsPoECards';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Power over Ethernet",
  "path": "/Products/Power_over_Ethernet/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "The IN-PoE 1000 will combine the network signal and power so it can be transferred over a single network cable to a PoE compatible network device.",
  "image": "./QI_SearchThumb_PowerOverEthernet.png",
  "social": "/images/Search/QI_SearchThumb_PowerOverEthernet.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-PoE_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Power over Ethernet' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-PoE 1000 will combine the network signal and power so it can be transferred over a single network cable to a PoE compatible network device.' image='/images/Search/QI_SearchThumb_PowerOverEthernet.png' twitter='/images/Search/QI_SearchThumb_PowerOverEthernet.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/Power_over_Ethernet/' locationFR='/fr/Products/Power_over_Ethernet/' crumbLabel="PoE" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "power-over-ethernet",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#power-over-ethernet",
        "aria-label": "power over ethernet permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Power over Ethernet`}</h1>
    <h2 {...{
      "id": "what-is-a-poe-injector",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-is-a-poe-injector",
        "aria-label": "what is a poe injector permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is a PoE Injector?`}</h2>
    <p>{`The IN-PoE 1000 will combine the network signal and power so it can be transferred over a single network cable to a PoE compatible network device. For this it is important that your end device supports the PoE (Power over Ethernet) function. At this time the IN-2905, IN-5907HD, IN-3010 and IN-6012HD are available as PoE models. Those camera models will be compatible with the IN-PoE 1000 PoE Injector. The network signal can be transmitted with up to `}<strong parentName="p">{`1000MBits`}</strong>{` while the injector works with up to `}<strong parentName="p">{`48V and 35 Watt`}</strong>{`. The injector meets the `}<strong parentName="p">{`IEEE 802.3at standard`}</strong>{`.`}</p>
    <PoECardsPoECards mdxType="PoECardsPoECards" />
    <p>{`With the IN-PoE 1000 you are always on the save side - The PoE injector detects if your end device supports PoE or not automatically and turns the power off in case your device is not compliant. This way your device can not be damaged even so it does not support PoE.`}</p>
    <h3 {...{
      "id": "your-advantages",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#your-advantages",
        "aria-label": "your advantages permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Your Advantages:`}</h3>
    <ul>
      <li parentName="ul">{`Fast Gigabit transfer rates (1000MBit)`}</li>
      <li parentName="ul">{`The IN-PoE 1000 Can handle devices with up to 35 Watt of power consumption. While most other injectors only support 15,6 Watt`}</li>
      <li parentName="ul">{`Thanks to a simply sliding system you can combine several injectors with each other in order to save space`}</li>
      <li parentName="ul">{`Easy plug & play installation`}</li>
    </ul>
    <h4 {...{
      "id": "content",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#content",
        "aria-label": "content permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Content:`}</h4>
    <p>{`1x IN-PoE1000 (PoE Injector) in black or white
1x Power cable
1x Quick installation guide`}</p>
    <h4 {...{
      "id": "specifications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#specifications",
        "aria-label": "specifications permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Specifications`}</h4>
    <p>{`Product name: IN-Poe 1000 Gigabit IEEE 802.3at PoE Adapter
Input voltage: 100 - 240 V
Output voltage: up to 48V @ 35 W
Weight x height x depth: 88 x 51 x 166 mm`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      